import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box } from '@mui/material';

export type AutoCheckProps = {
	autoCheck: boolean | null;
};

export const AutoCheck = ({ autoCheck }: AutoCheckProps): JSX.Element => (
	<Box justifyContent="center" alignItems="center" display="flex">
		{autoCheck === null ? (
			<RemoveCircleOutlineOutlinedIcon
				color="disabled"
				fontSize="small"
			/>
		) : autoCheck ? (
			<CheckCircleIcon color="success" fontSize="small" />
		) : (
			<CancelOutlinedIcon color="error" fontSize="small" />
		)}
	</Box>
);
