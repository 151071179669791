import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { UserInvite, UserInviteStatus } from '../../../constants/UserInvite';
import { ConfirmationDialog } from '../../Dialogs/ConfirmationDialog';

export type UserInviteOptionsProps = {
	id: string;
	status: UserInvite['status'];
	resendUserInvite: () => Promise<void>;
	revokeUserInvite: () => Promise<void>;
};

export const UserInviteOptions = ({
	id,
	status,
	resendUserInvite,
	revokeUserInvite,
}: UserInviteOptionsProps): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleResendUserInvite = async (): Promise<void> => {
		setLoading(true);
		await resendUserInvite();
		setLoading(false);
	};
	const handleRevokeUserInvite = async (): Promise<void> => {
		setLoading(true);
		await revokeUserInvite();
		setLoading(false);
		setIsOpen(false);
	};

	return (
		<>
			<Grid
				container
				spacing={1}
				justifyContent="center"
				display="flex"
				textAlign="center">
				{status === UserInviteStatus.Pending && (
					<>
						<Grid item xl={6}>
							<LoadingButton
								size="small"
								fullWidth
								onClick={handleResendUserInvite}
								variant="outlined"
								sx={{ minWidth: 76 }}
								loading={loading}
								data-testid={`${id}-resend-button`}>
								Resend
							</LoadingButton>
						</Grid>
						<Grid item xl={6}>
							<LoadingButton
								size="small"
								onClick={(): void => setIsOpen(true)}
								variant="outlined"
								color="primary"
								fullWidth
								sx={{ minWidth: 76 }}
								loading={loading}
								data-testid={`${id}-revoke-button`}>
								Revoke
							</LoadingButton>
						</Grid>
					</>
				)}
				{status === UserInviteStatus.Expired && (
					<Grid item display="flex" justifyContent="center">
						<LoadingButton
							size="small"
							onClick={handleResendUserInvite}
							variant="outlined"
							fullWidth
							loading={loading}
							data-testid={`${id}-resend-button`}
							sx={{ minWidth: 76 }}>
							Resend
						</LoadingButton>
					</Grid>
				)}
			</Grid>
			<ConfirmationDialog
				title="Revoke User Invite?"
				question="Are you sure you want to revoke this user invite?"
				isOpen={isOpen}
				onClose={(): void => setIsOpen(false)}
				onConfirm={handleRevokeUserInvite}
			/>
		</>
	);
};
