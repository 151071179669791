import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
	Autocomplete,
	Box,
	Checkbox,
	ListItem,
	Paper,
	TextField,
} from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Activity, UserProps, accountTypes } from '../../../constants/Common';
import { TimesheetStatus } from '../../../constants/Timesheet/TimesheetStatus';
import type { FirebaseApi } from '../../../firebase/firebaseApi';
import { DataTable } from '../../DataTable/DataTable';
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
// eslint disagrees with import order here for an unfathomable reason
// eslint-disable-next-line import/order
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { ReportConfigureHeader } from '../ReportConfigureHeader';
import { reportsCustomTableFilter } from '../ReportsCustomTableFilter';
import { ReportsStyleWrapper } from '../ReportsStyleWrapper';
import {
	ActivitiesReportFirebaseCalls,
	FetchButtonState,
} from '../StateManagement/models';
import { ActivitiesReportSummary } from './ActivitiesReportSummary';

type ValidStatus =
	| TimesheetStatus.Approved
	| TimesheetStatus.Archived
	| TimesheetStatus.Submitted;

const validStatuses: readonly ValidStatus[] = [
	TimesheetStatus.Approved,
	TimesheetStatus.Archived,
	TimesheetStatus.Submitted,
];

export type ActivitiesReportProps = UserProps & {
	startDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	endDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	firebaseApi: Pick<FirebaseApi, ActivitiesReportFirebaseCalls>;
};

export const ActivitiesReport = ({
	userDetails,
	startDateContext,
	endDateContext,
	firebaseApi,
}: ActivitiesReportProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [tableHeader, setTableHeader] = useState('Activity Report');
	const [openSummary, setOpenSummary] = useState(false);
	const [fetchButtonText, setFetchButtonText] =
		useState<FetchButtonState>('Update');
	const [tempStartDate, setTempStartDate] = useState(startDateContext.date);
	const [tempEndDate, setTempEndDate] = useState(endDateContext.date);
	const [shouldUpdate, setShouldUpdate] = useState(false);
	const [activityTableData, setTableActivityData] = useState<Activity[]>([]);
	const [statuses, setStatuses] = useState<ValidStatus[]>([
		TimesheetStatus.Approved,
		TimesheetStatus.Archived,
	]);

	const noMatchTableText = initialLoad ? (
		'Configure report information above and press update'
	) : loading ? (
		<LoadingDots />
	) : (
		'Sorry, no matching activities found'
	);
	const cols: MUIDataTableColumnDef[] = [
		{
			label: 'Employee',
			name: 'workerName',
			options: {
				filterType: 'custom',
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: 'Employer',
			name: 'employerName',
			options: {
				filterType: 'custom',
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: 'Site',
			name: 'siteName',
			options: {
				filterType: 'custom',
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: 'Day',
			name: 'date',
			options: {
				filter: false,
				customBodyRender: (day: string): JSX.Element => (
					<Box width="100%">{day}</Box>
				),
			},
		},
		{
			label: 'Week Ending',
			name: 'weekEnding',
			options: {
				filter: false,
				customBodyRender: (week: string): JSX.Element => (
					<Box width="100%">{week}</Box>
				),
			},
		},
		{
			label: 'Activity',
			name: 'activity',
			options: {
				customBodyRender: (activity: string): JSX.Element => (
					<Box width="100%">{activity}</Box>
				),
				filterType: 'custom',
				filterOptions: {
					logic: (name, filters, _): boolean =>
						filters.length ? !filters.includes(name) : false,
					display: reportsCustomTableFilter,
				},
			},
		},
		{
			label: 'Hours',
			name: 'hours',
			options: {
				filter: false,
			},
		},
	];

	const customTableOptions: Partial<MUIDataTableOptions> = {
		filter: true,
		tableBodyHeight: '100%',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		isRowSelectable: () => false,
		responsive: 'standard',
		downloadOptions: {
			filename: tableHeader
				.replace(new RegExp('/', 'g'), '-')
				.replace(/\s/g, '_'),
		},
	};

	const getActivitiesFromTimesheetIDS = useCallback(
		async (timesheetIDs: string[]): Promise<Activity[]> => {
			// Fetch activities for each timesheet ID
			const allActivitiesPromises = timesheetIDs.map((timesheetID) =>
				firebaseApi.getActivitiesByStatusTimesheet(
					statuses,
					timesheetID,
					// the start and end dates provided by users don't necessarily line up by the timesheet.weekEnding, which is what we query
					// so we must also provide the date here
					startDateContext.date,
					endDateContext.date,
				),
			);

			// Wait for all fetch promises to complete
			const allActivities = await Promise.all(allActivitiesPromises);
			const activitiesArray = allActivities.flat();
			return activitiesArray;
		},
		[endDateContext.date, firebaseApi, startDateContext.date, statuses],
	);

	const getActivities = useCallback(async (): Promise<Activity[]> => {
		if (!userDetails.companyID) return [];
		switch (userDetails.accountType) {
			case accountTypes.management: {
				// management pull timesheets by contractedTo, Site, date range
				// then pull all acivities matching the timesheet ids and status
				if (!userDetails.siteID) return [];
				const timesheets =
					await firebaseApi.getTimesheetsByWeekSiteContractedStatus(
						startOfWeek(startDateContext.date),
						endOfWeek(endDateContext.date),
						userDetails.siteID,
						userDetails.companyID,
						[
							TimesheetStatus.Active,
							TimesheetStatus.Approved,
							TimesheetStatus.Archived,
							TimesheetStatus.Submitted,
						],
					);
				const timesheetIDs = timesheets.map(
					(timesheet) => timesheet.id,
				);
				return getActivitiesFromTimesheetIDS(timesheetIDs);
			}
			// senior management pull timesheets by contractedTo and date range
			// then pull all acivities matching the timesheet ids and status
			case accountTypes.seniorManagement: {
				const timesheets =
					await firebaseApi.getTimesheetsByWeekContractedStatus(
						startOfWeek(startDateContext.date),
						endOfWeek(endDateContext.date),
						userDetails.companyID,
						[
							TimesheetStatus.Active,
							TimesheetStatus.Approved,
							TimesheetStatus.Archived,
							TimesheetStatus.Submitted,
						],
					);
				const timesheetIDs = timesheets.map(
					(timesheet) => timesheet.id,
				);
				return getActivitiesFromTimesheetIDS(timesheetIDs);
			}
		}
		return [];
	}, [
		userDetails,
		firebaseApi,
		startDateContext.date,
		endDateContext.date,
		getActivitiesFromTimesheetIDS,
	]);

	useEffect(() => {
		setTempStartDate(startDateContext.date);
	}, [startDateContext.date]);

	useEffect(() => {
		setTempEndDate(endDateContext.date);
	}, [endDateContext.date]);

	useEffect(() => {
		setFetchButtonText('Update');
	}, [tempStartDate, tempEndDate]);

	useEffect(() => {
		if (!shouldUpdate) return;
		let mounted = true;
		const setSiteLogData = async (): Promise<void> => {
			setLoading(true);
			const activities = await getActivities();
			if (mounted) {
				setTableHeader(
					`Activity Report ${formatSlashedDate(
						startDateContext.date,
					)} - ${formatSlashedDate(endDateContext.date)}`,
				);
				setTableActivityData(activities);
				setShouldUpdate(false);
				setLoading(false);
				setOpenSummary(true);
			}
		};
		setSiteLogData();
		return (): void => {
			mounted = false;
		};
	}, [
		shouldUpdate,
		startDateContext.date,
		endDateContext.date,
		getActivities,
	]);

	const handleUpdateOnClick = (): void => {
		if (fetchButtonText === 'Refresh') {
			setShouldUpdate(true);
		} else {
			if (initialLoad) setInitialLoad(false);
			setFetchButtonText('Refresh');
			startDateContext.setDate(tempStartDate);
			endDateContext.setDate(tempEndDate);
			setShouldUpdate(true);
		}
	};
	const renderReportHeader = (
		<ReportConfigureHeader
			loading={loading}
			updateOnClick={handleUpdateOnClick}
			updateButtonText={fetchButtonText}
			disabled={statuses.length < 1}
		/>
	);
	const renderDateSelector = (
		<DateRangeSelector
			startDateUseState={[tempStartDate, setTempStartDate]}
			endDateUseState={[tempEndDate, setTempEndDate]}
			textFieldProps={{
				size: 'small',
				fullWidth: true,
			}}
		/>
	);

	const renderStatusAutoComplete = (
		<Autocomplete
			fullWidth
			multiple
			size="small"
			disableCloseOnSelect
			options={validStatuses}
			value={statuses}
			onChange={(_, statuses): void => {
				setStatuses(statuses);
				setFetchButtonText('Update');
			}}
			renderInput={(params): JSX.Element => (
				<TextField {...params} label="Status" />
			)}
			renderOption={(props, option, { selected }): JSX.Element => (
				<ListItem {...props}>
					<Checkbox
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={<CheckBoxIcon fontSize="small" />}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{option}
				</ListItem>
			)}
		/>
	);

	return (
		<Box
			sx={{
				height: 'calc(100vh - 190px)',
				overflow: 'auto',
				display: 'flex',
				flexDirection: 'column',
			}}>
			<Box>
				<ReportsStyleWrapper
					title={renderReportHeader}
					componentList={[
						{
							gridSize: 5,
							component: renderDateSelector,
							id: 'dateSelector',
						},
						{
							gridSize: 7,
							component: renderStatusAutoComplete,
							id: 'statusAutoComplete',
						},
					]}
				/>
			</Box>
			<Paper
				sx={{
					paddingTop: 2,
					borderRadius: 0,
				}}>
				<ActivitiesReportSummary
					activities={activityTableData}
					open={openSummary}
					setOpen={setOpenSummary}
				/>
			</Paper>
			<DataTable
				title={tableHeader}
				tableData={activityTableData.map((activity) => ({
					...activity,
					activity: activity.activity.name,
					date: formatSlashedDate(activity.date.toDate()),
					weekEnding: formatSlashedDate(activity.weekEnding.toDate()),
				}))}
				columns={cols}
				customTableOptions={customTableOptions}
			/>
		</Box>
	);
};
