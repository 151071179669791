import { LoadingButton } from '@mui/lab';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { useState } from 'react';

type ConfirmationDialogProps = {
	title: string;
	question: string;
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => Promise<void>;
};

export const ConfirmationDialog = ({
	title,
	question,
	isOpen,
	onClose,
	onConfirm,
}: ConfirmationDialogProps): JSX.Element => {
	const [loading, setLoading] = useState(false);

	const handleConfirm = async (): Promise<void> => {
		setLoading(true);
		await onConfirm();
		setLoading(false);
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth={true}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{question}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					onClick={onClose}
					variant="outlined"
					loading={loading}>
					No
				</LoadingButton>
				<LoadingButton
					onClick={handleConfirm}
					variant="contained"
					loading={loading}>
					Yes
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
