import {
	Grid,
	GridProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { SummarySectionNames } from './SummarySectionNames';

export type SummaryRowProps = {
	day: JSX.Element;
	autoCheck: JSX.Element;
	sitelog: JSX.Element;
	breaks: JSX.Element;
	totalPaid: JSX.Element;
	customContainerProps?: GridProps & { 'data-testid'?: string };
};

export const SummaryRow = ({
	day,
	autoCheck,
	sitelog,
	breaks,
	totalPaid,
	customContainerProps,
}: SummaryRowProps): JSX.Element => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const EQUAL_COLUMN_WIDTH = 12 / 5;

	return (
		<Grid container columnSpacing={1} {...customContainerProps}>
			<Grid item xs={12} sm={EQUAL_COLUMN_WIDTH}>
				{day}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={6}>
					<Typography>{SummarySectionNames.AUTO_CHECK}</Typography>
				</Grid>
			)}
			<Grid item xs={6} sm={EQUAL_COLUMN_WIDTH}>
				{autoCheck}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={6}>
					<Typography>{SummarySectionNames.SITE_LOGS}</Typography>
				</Grid>
			)}
			<Grid item xs={6} sm={EQUAL_COLUMN_WIDTH}>
				{sitelog}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={6}>
					<Typography>{SummarySectionNames.BREAKS}</Typography>
				</Grid>
			)}
			<Grid item xs={6} sm={EQUAL_COLUMN_WIDTH}>
				{breaks}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={6}>
					<Typography>{SummarySectionNames.TOTAL_PAID}</Typography>
				</Grid>
			)}
			<Grid item xs={6} sm={EQUAL_COLUMN_WIDTH}>
				{totalPaid}
			</Grid>
		</Grid>
	);
};
