import { Paper, Stack } from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';
import { useEffect, useMemo, useReducer } from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import {
	Activity,
	Company,
	CompanyTypes,
	DayString,
	dayStrings,
	lowercaseDayString,
	Site,
	UserDetails,
} from '../../../../constants/Common';
import {
	InvoiceStatuses,
	ProjectTrackingStatuses,
	Timesheet,
	TimesheetPayrollStatuses,
	WorkHistoryStatus,
} from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import {
	getRates,
	NewTimesheetStatus,
} from '../../../../constants/Timesheet/TimesheetUtilities';
import { Timestamp } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../../providers/UserProvider';
import { formatActivityDisplayDay } from '../../../helpers/dateFormatters';
import { getDayOfWeekDate } from '../../../helpers/dateUtilities';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { splitSiteLogsByDay } from '../Details/timesheetTableUtilities';
import {
	addActivity,
	clearTimesheet,
	closeConfirmDialog,
	deleteActivity,
	selectClient,
	selectSite,
	selectTimesheetStatus,
	selectWeekEnding,
	selectWorker,
	updateActivity,
	updateBreak,
	updateLoading,
	updateNote,
	updateSiteLogs,
	validateTimesheet,
} from './actions';
import { CreateWeekList } from './CreateBody/CreateWeekList';
import { CreateFooter } from './CreateFooter';
import { CreateHeader } from './CreateHeader';
import { isNonNullNewActivity, NewActivity } from './model';
import { createInitialCreateState, createTimesheetReducer } from './reducer';
import { CreateSummaryDialog } from './SummaryDialog/CreateSummaryDialog';

const TABLE_MIN_WIDTH = 330;

export type CreateFirebaseCalls =
	| 'createTimesheet'
	| 'getUser'
	| 'getLimitedContractByEmployeeSupplierAccepterStatus'
	| 'getLimitedContractsByEmployeeSiteSupplierAccepterStatus'
	| 'userSitelogsForWeekSubscription';

export type CreateProps = {
	loading: boolean;
	userDetails: UserDetails;
	users: Record<string, UserDetails>;
	companies: Record<string, Company>;
	sites: Record<string, Site>;
	weekEndingQueryParam: Date;
	setWeekQueryParams: (weekEnding: Date) => void;
	firebaseApi: Pick<FirebaseApi, CreateFirebaseCalls>;
	cloudFunctionApi: Pick<CloudFunctionApi, 'createTimesheetNote'>;
};

export const Create = ({
	loading,
	userDetails,
	users,
	companies,
	sites,
	weekEndingQueryParam,
	setWeekQueryParams,
	firebaseApi,
	cloudFunctionApi,
}: CreateProps): JSX.Element => {
	const abortSignal = useAbortController();
	const user = useUserAuthContext();

	const [state, dispatch] = useReducer(
		createTimesheetReducer,
		{ userDetails, sites, weekEnding: weekEndingQueryParam, loading },
		createInitialCreateState,
	);

	const companyOptions = useMemo(() => {
		const filteredCompanies = Object.values(companies)
			.filter(
				(company) =>
					(state.authorizedActions.canChangeClient &&
						company.companyType === CompanyTypes.construction) ||
					(!state.authorizedActions.canChangeClient &&
						company.id === userDetails.companyID) ||
					(state.authorizedActions.isRecruitmentSite &&
						company.id === userDetails.companyID),
			)
			.reduce<Record<string, Company>>((companies, company) => {
				companies[company.id] = company;
				return companies;
			}, {});
		return filteredCompanies;
	}, [
		companies,
		state.authorizedActions.canChangeClient,
		state.authorizedActions.isRecruitmentSite,
		userDetails.companyID,
	]);

	// ../Timesheets.tsx isn't handling loading properly
	// results in changing tab not defaulting site but page reload does.
	// This is a temporary fix to ensure the site is selected but at a lag
	useEffect(() => {
		const defaultSite = sites[userDetails.siteID];
		if (defaultSite && !state.selectedSite) {
			dispatch(selectSite(defaultSite, true));
		}
		// We don't want this to run every time state.selectedSite changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sites, userDetails.siteID]);

	// Also a side effect of the parent component not handling loading properly
	// only load on initial load
	useEffect(() => {
		if (state.initialLoad && !loading) {
			dispatch(updateLoading(loading));
		}
	}, [loading, state.initialLoad]);

	useEffect(() => {
		if (
			state.selectedWorker !== null &&
			state.selectedClient !== null &&
			state.selectedSite !== null &&
			state.selectedWeekEnding !== null
		) {
			return firebaseApi.userSitelogsForWeekSubscription(
				state.selectedWorker.userID,
				state.selectedSite.id,
				state.selectedWeekEnding,
				(siteLogs) => {
					dispatch(updateSiteLogs(siteLogs));
				},
			);
		} else {
			dispatch(updateSiteLogs([]));
		}
	}, [
		firebaseApi,
		state.selectedClient,
		state.selectedSite,
		state.selectedWeekEnding,
		state.selectedWorker,
	]);

	const handleSelectWorker = (userID: string): void => {
		dispatch(selectWorker(users[userID] ?? null));
	};

	const handleSelectClient = (
		client: Pick<Company, 'id' | 'name'> | null,
	): void => {
		dispatch(selectClient(client));
	};

	const handleSelectSite = (site: Site | null): void => {
		dispatch(selectSite(site));
	};

	const handleSelectTimesheetStatus = (status: NewTimesheetStatus): void => {
		dispatch(selectTimesheetStatus(status));
	};

	const handleSelectWeekEnding = (date: Date | null): void => {
		const weekEnding = date ?? endOfWeek(new Date());
		setWeekQueryParams(weekEnding);
		dispatch(selectWeekEnding(weekEnding));
	};

	const handleAddActivity = (day: DayString): void => {
		dispatch(addActivity(day));
	};

	const handleUpdateActivity = (activity: NewActivity): void => {
		dispatch(updateActivity(activity));
	};

	const handleDeleteActivity = (day: DayString, id: string): void => {
		dispatch(deleteActivity(day, id));
	};

	const handleUpdateBreak = (day: DayString, breakHours: number): void => {
		dispatch(updateBreak(day, breakHours));
	};

	const handleUpdateNote = (day: DayString, note: string): void => {
		dispatch(updateNote(day, note));
	};

	const handleCancel = (): void => {
		dispatch(clearTimesheet());
	};

	const handleSubmit = (): void => {
		dispatch(validateTimesheet());
	};

	const handleCloseDialog = (): void => {
		dispatch(closeConfirmDialog());
	};

	const handleCreateTimesheet = async (): Promise<void> => {
		if (
			state.selectedWorker === null ||
			state.selectedClient === null ||
			state.selectedSite === null ||
			state.selectedWeekEnding === null ||
			user === null
		) {
			return;
		}

		const rates = await getRates(
			state.selectedWorker.userID,
			state.selectedSite.id,
			state.selectedWorker.companyID,
			state.selectedClient.id,
			firebaseApi,
		);

		const newTimesheet: Omit<Timesheet, 'id'> = {
			employee: {
				id: state.selectedWorker.userID,
				name: state.selectedWorker.displayName,
				paid: false,
				type: state.selectedWorker.workerType,
			},
			employer: {
				id: state.selectedWorker.companyID,
				name: state.selectedWorker.company,
			},
			site: {
				id: state.selectedSite.id,
				name: state.selectedSite.name,
				company: state.selectedSite.company,
				companyID: state.selectedSite.companyID,
			},
			contractedTo: {
				id: state.selectedClient.id,
				name: state.selectedClient.name,
			},
			timesheetStatus: state.selectedTimesheetStatus,
			dateSubmitted: Timestamp.now(),
			week: Timestamp.fromDate(startOfWeek(state.selectedWeekEnding)),
			weekEnding: Timestamp.fromDate(state.selectedWeekEnding),
			contract: rates,
			hours: {
				monday: {
					billable: 0,
					break: state.breaks.Monday,
				},
				tuesday: {
					billable: 0,
					break: state.breaks.Tuesday,
				},
				wednesday: {
					billable: 0,
					break: state.breaks.Wednesday,
				},
				thursday: {
					billable: 0,
					break: state.breaks.Thursday,
				},
				friday: {
					billable: 0,
					break: state.breaks.Friday,
				},
				saturday: {
					billable: 0,
					break: state.breaks.Saturday,
				},
				sunday: {
					billable: 0,
					break: state.breaks.Sunday,
				},
				total: {
					billable: 0,
					break: Object.values(state.breaks).reduce(
						(totalBreaks, unpaidBreak) => totalBreaks + unpaidBreak,
						0,
					),
				},
			},
			cost: {
				billable: 0,
			},
			reviewedAt: null,
			reviewer: null,
			lastEditedBy: {
				id: userDetails.userID,
				name: userDetails.displayName,
			},
			invoiceStatus: InvoiceStatuses.Unsent,
			payrollStatus: TimesheetPayrollStatuses.Unsent,
			projectTrackingStatus: ProjectTrackingStatuses.Unsent,
			workHistoryStatus: WorkHistoryStatus.Unsent,
		};
		if (state.selectedTimesheetStatus === TimesheetStatus.Approved) {
			newTimesheet.reviewedAt = Timestamp.now();
			newTimesheet.reviewer = {
				id: userDetails.userID,
				name: userDetails.displayName,
			};
		}
		const allActivities = Object.values(state.activities)
			.flatMap<NewActivity>(Object.values)
			.filter(isNonNullNewActivity)
			.reduce<Omit<Activity, 'id' | 'timesheetID'>[]>(
				(allNewActivities, activity) => {
					const activityDate = getDayOfWeekDate(
						newTimesheet.week.toDate(),
						activity.day,
					);
					const newActivity: Omit<Activity, 'id' | 'timesheetID'> = {
						activity: activity.activity,
						day: activity.day,
						date: Timestamp.fromDate(activityDate),
						displayDay: formatActivityDisplayDay(activityDate),
						employerID: newTimesheet.employer.id,
						employerName: newTimesheet.employer.name,
						finalReviewAt: newTimesheet.reviewedAt,
						finalReviewBy:
							newTimesheet.reviewer == null
								? null
								: newTimesheet.reviewer.name,
						hours: activity.hours,
						rate: rates.chargeOutRate,
						siteID: newTimesheet.site.id,
						siteName: newTimesheet.site.name,
						siteCompany: newTimesheet.site.company,
						siteCompanyID: newTimesheet.site.companyID,
						status: newTimesheet.timesheetStatus,
						totalCost: activity.hours * rates.chargeOutRate,
						week: newTimesheet.week,
						weekEnding: newTimesheet.weekEnding,
						workerID: newTimesheet.employee.id,
						workerName: newTimesheet.employee.name,
					};

					const field = lowercaseDayString(newActivity.day);
					newTimesheet.hours[field].billable += newActivity.hours;
					newTimesheet.hours.total.billable += newActivity.hours;
					allNewActivities.push(newActivity);
					return allNewActivities;
				},
				[],
			);
		newTimesheet.cost.billable =
			newTimesheet.contract.chargeOutRate *
			newTimesheet.hours.total.billable;
		const timesheetID = await firebaseApi.createTimesheet(
			newTimesheet,
			allActivities,
		);
		const newNotes = dayStrings
			.filter((day) => state.notes[day].note.trim() !== '')
			.map((day) => {
				return cloudFunctionApi.createTimesheetNote(
					abortSignal,
					user,
					timesheetID,
					day,
					state.notes[day].note,
				);
			});
		await Promise.all(newNotes);

		dispatch(clearTimesheet());
	};

	return state.initialLoad ? (
		<LoadingDots />
	) : (
		<Paper elevation={0} sx={{ overflow: 'auto' }}>
			<Stack
				height="calc(100vh - 189px)"
				spacing={1}
				minWidth={TABLE_MIN_WIDTH}>
				<CreateHeader
					users={Object.values(users)}
					companies={companyOptions}
					sites={sites}
					selectedWorker={state.selectedWorker}
					handleSelectWorker={handleSelectWorker}
					selectedClient={state.selectedClient}
					handleSelectClient={handleSelectClient}
					selectedTimesheetStatus={state.selectedTimesheetStatus}
					handleSelectTimesheetStatus={handleSelectTimesheetStatus}
					selectedSite={state.selectedSite}
					handleSelectSite={handleSelectSite}
					selectedWeekEnding={state.selectedWeekEnding}
					handleSelectedWeekEnding={handleSelectWeekEnding}
					disableClientSelect={
						!state.authorizedActions.canChangeClient ||
						state.authorizedActions.isRecruitmentSite
					}
					disableSiteSelect={!state.authorizedActions.canChangeSite}
					disableStatusSelect={
						!state.authorizedActions.canChangeStatus
					}
					headerErrors={state.headerErrors}
				/>
				<CreateWeekList
					activityOptions={
						state.selectedSite?.timesheetActivitiesV2 ?? null
					}
					siteLogs={splitSiteLogsByDay(state.siteLogs)}
					activities={state.activities}
					addActivity={handleAddActivity}
					updateActivity={handleUpdateActivity}
					deleteActivity={handleDeleteActivity}
					breaks={state.breaks}
					updateBreak={handleUpdateBreak}
					notes={state.notes}
					updateNote={handleUpdateNote}
				/>
				<CreateFooter
					disabled={
						Object.values(state.activities).flatMap(Object.keys)
							.length === 0
					}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
				/>
			</Stack>
			{state.selectedWorker &&
				state.selectedSite &&
				state.selectedClient && (
					<CreateSummaryDialog
						open={state.confirmModalOpen}
						timesheetStatus={state.selectedTimesheetStatus}
						site={state.selectedSite}
						employee={state.selectedWorker}
						employer={state.selectedClient}
						weekEnding={state.selectedWeekEnding}
						activities={state.activities}
						breaks={state.breaks}
						siteLogs={splitSiteLogsByDay(state.siteLogs)}
						onSubmit={handleCreateTimesheet}
						onClose={handleCloseDialog}
					/>
				)}
		</Paper>
	);
};
