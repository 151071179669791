import { UserInvite } from '../constants/UserInvite';
import { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type RevokeUserInviteBody = { id: UserInvite['id'] };

export const revokeUserInvite = async (
	abortSignal: AbortSignal,
	user: User,
	body: RevokeUserInviteBody,
): Promise<boolean> =>
	await makeCloudFunctionRequestReturnSuccessStatus<RevokeUserInviteBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.RevokeUserInvite,
		method: 'DELETE',
		services: defaultCloudFunctionServices,
		body,
	});
