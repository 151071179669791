import { Button, Stack } from '@mui/material';

export type CreateFooterProps = {
	disabled: boolean;
	onSubmit: () => void;
	onCancel: () => void;
};

export const CreateFooter = ({
	disabled,
	onSubmit,
	onCancel,
}: CreateFooterProps): JSX.Element => {
	return (
		<Stack
			direction="row"
			spacing={1}
			paddingX={2}
			paddingBottom={1}
			justifyContent="flex-end">
			<Button variant="outlined" onClick={onCancel}>
				Cancel
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={onSubmit}
				disabled={disabled}>
				Submit
			</Button>
		</Stack>
	);
};
