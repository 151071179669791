import { Box, Stack, Typography } from '@mui/material';
import { WorkerAttendance } from '../../constants/Attendance';
import { StringAvatar } from '../Avatar/StringAvatar';
import { formatLowercaseTime } from '../helpers/dateFormatters';

type AttendanceCardProps = {
	employee: WorkerAttendance;
};

export const AttendanceCard = ({
	employee,
}: AttendanceCardProps): JSX.Element => {
	const signInTime =
		employee.datetime && formatLowercaseTime(employee.datetime.toDate());
	return (
		<Stack direction="row" spacing={2} alignItems="center">
			<StringAvatar name={employee.displayName} />
			<Box>
				<Typography variant="h7Bold">{employee.displayName}</Typography>
				<Typography fontSize="x-small">{employee.company}</Typography>
				<Typography fontSize="x-small">{employee.site}</Typography>
				<Typography fontSize="x-small">
					{employee.mobileNumber}
				</Typography>
			</Box>
			{signInTime && (
				<Typography fontSize="small">{signInTime}</Typography>
			)}
		</Stack>
	);
};
