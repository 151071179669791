import { Guest } from '../../constants/Common';

// Type guard for Guest
export const isGuest = (person: unknown): person is Guest => {
	return (
		typeof person === 'object' &&
		person !== null &&
		'id' in person &&
		'company' in person &&
		'displayName' in person &&
		'signedIn' in person &&
		'site' in person &&
		'siteID' in person &&
		'mobileNumber' in person
	);
};
