import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useState } from 'react';
import { DayString, dayStrings } from '../../../../../constants/Common';
import { getTimeForHours } from '../../../../../constants/stringUtilities';
import { TimesheetSiteLog } from '../../../../../constants/Timesheet/Timesheet';
import { formatSpacedDate } from '../../../../helpers/dateFormatters';
import { TimesheetStatusChip } from '../../../TimesheetStatusChip';
import { AutoCheck } from '../../Details/AutoCheck';
import { calculateAutoCheck } from '../../Details/timesheetTableUtilities';
import { CreateColumnTitle } from '../CreateBody/CreateColumnTitle';
import { CreateState, isNonNullNewActivity, NewActivity } from '../model';
import { SummaryFooter } from './SummaryFooter';
import { SummaryRow } from './SummaryRow';
import { SummarySectionNames } from './SummarySectionNames';

export type CreateSummaryDialogProps = {
	open: boolean;
	timesheetStatus: CreateState['selectedTimesheetStatus'];
	site: NonNullable<CreateState['selectedSite']>;
	employee: NonNullable<CreateState['selectedWorker']>;
	employer: NonNullable<CreateState['selectedClient']>;
	weekEnding: CreateState['selectedWeekEnding'];
	activities: CreateState['activities'];
	breaks: CreateState['breaks'];
	siteLogs: Record<
		DayString,
		{
			In: TimesheetSiteLog | null;
			Out: TimesheetSiteLog | null;
			formattedLogs: string | null;
		}
	>;
	onSubmit: () => Promise<void>;
	onClose: () => void;
};

export const CreateSummaryDialog = ({
	open,
	timesheetStatus,
	site,
	employee,
	employer,
	weekEnding,
	activities,
	breaks,
	siteLogs,
	onSubmit,
	onClose,
}: CreateSummaryDialogProps): JSX.Element => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [loading, setLoading] = useState(false);

	const handleOnSubmit = async (): Promise<void> => {
		setLoading(true);
		await onSubmit();
	};

	return (
		<Dialog
			open={open}
			maxWidth="md"
			fullWidth
			data-testid="create-summary-dialog">
			<DialogTitle
				display="flex"
				justifyContent="space-between"
				alignItems="center">
				<Typography variant="h6Bold">
					Create Timesheet WE {formatSpacedDate(weekEnding)}
				</Typography>
				<TimesheetStatusChip status={timesheetStatus} />
			</DialogTitle>
			<DialogContent>
				<Grid
					container
					spacing={1}
					justifyContent="space-between"
					alignItems="center">
					<Grid item xs={12} sm={6} md={4}>
						<Typography fontWeight="bold">Employee</Typography>
						<Typography fontSize="small">
							{employee.displayName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Typography fontWeight="bold">Site</Typography>
						<Typography fontSize="small">{site.name}</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Typography fontWeight="bold">Employer</Typography>
						<Typography fontSize="small">
							{employer.name}
						</Typography>
					</Grid>
				</Grid>
				<Stack rowGap={1} paddingTop={2}>
					{!isSmallScreen && (
						<SummaryRow
							day={<></>}
							autoCheck={
								<CreateColumnTitle
									centerText
									title={SummarySectionNames.AUTO_CHECK}
								/>
							}
							sitelog={
								<CreateColumnTitle
									centerText
									title={SummarySectionNames.SITE_LOGS}
								/>
							}
							breaks={
								<CreateColumnTitle
									centerText
									title={SummarySectionNames.BREAKS}
								/>
							}
							totalPaid={
								<CreateColumnTitle
									centerText
									title={SummarySectionNames.TOTAL_PAID}
								/>
							}
						/>
					)}
					{dayStrings.map((day) => (
						<SummaryRow
							key={day}
							customContainerProps={{
								'data-testid': `summary-row-${day}`,
							}}
							day={
								<Typography fontWeight="bold">{day}</Typography>
							}
							autoCheck={
								<AutoCheck
									autoCheck={calculateAutoCheck(
										siteLogs[day],
										Object.values(activities[day]).filter(
											isNonNullNewActivity,
										),
										breaks[day],
									)}
								/>
							}
							sitelog={
								<Typography textAlign="center">
									{siteLogs[day].formattedLogs ?? '-'}
								</Typography>
							}
							breaks={
								<Typography textAlign="center">
									{getTimeForHours(breaks[day])}
								</Typography>
							}
							totalPaid={
								<Typography textAlign="center">
									{getTimeForHours(
										Object.values(activities[day]).reduce(
											(total, activity) =>
												total + activity.hours,
											0,
										),
									)}
								</Typography>
							}
						/>
					))}
					<Divider />
					<SummaryFooter
						activities={Object.values(activities)
							.flatMap<NewActivity>(Object.values)
							.filter(isNonNullNewActivity)}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose} disabled={loading}>
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					onClick={handleOnSubmit}
					disabled={loading}
					loading={loading}>
					Submit
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
