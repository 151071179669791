import { DayString } from '../../../../constants/Common';
import { Action } from '../../../../constants/TypescriptUtilities';
import { CreateState, NewActivity } from './model';

type CreateAction<
	T extends CreateActionTypes,
	P extends object | void = void,
> = Action<T, P>;

export const enum CreateActionTypes {
	SELECT_WORKER = 'SELECT_WORKER',
	SELECT_CLIENT = 'SELECT_CLIENT',
	SELECT_SITE = 'SELECT_SITE',
	SELECT_TIMESHEET_STATUS = 'SELECT_TIMESHEET_STATUS',
	SELECT_WEEK_ENDING = 'SELECT_WEEK_ENDING',
	UPDATE_LOADING = 'UPDATE_LOADING',
	UPDATE_SITE_LOGS = 'UPDATE_SITE_LOGS',
	ADD_ACTIVITY = 'ADD_ACTIVITY',
	UPDATE_ACTIVITY = 'UPDATE_ACTIVITY',
	DELETE_ACTIVITY = 'DELETE_ACTIVITY',
	UPDATE_BREAK = 'UPDATE_BREAK',
	UPDATE_NOTE = 'UPDATE_NOTE',
	CLEAR_TIMESHEET = 'CLEAR_TIMESHEET',
	VALIDATE_TIMESHEET = 'VALIDATE_TIMESHEET',
	CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG',
}

type SelectWorkerAction = CreateAction<
	CreateActionTypes.SELECT_WORKER,
	{ worker: CreateState['selectedWorker'] }
>;

type SelectClientAction = Action<
	CreateActionTypes.SELECT_CLIENT,
	{ client: CreateState['selectedClient'] }
>;

type SelectSiteAction = Action<
	CreateActionTypes.SELECT_SITE,
	{ site: CreateState['selectedSite']; defaultSite: boolean }
>;

type SelectTimesheetStatusAction = Action<
	CreateActionTypes.SELECT_TIMESHEET_STATUS,
	{ timesheetStatus: CreateState['selectedTimesheetStatus'] }
>;

type SelectWeekEndingAction = Action<
	CreateActionTypes.SELECT_WEEK_ENDING,
	{ weekEnding: CreateState['selectedWeekEnding'] }
>;

type UpdateLoadingAction = Action<
	CreateActionTypes.UPDATE_LOADING,
	{ loading: CreateState['initialLoad'] }
>;

type UpdateSiteLogsAction = Action<
	CreateActionTypes.UPDATE_SITE_LOGS,
	{ siteLogs: CreateState['siteLogs'] }
>;

type AddActivityAction = Action<
	CreateActionTypes.ADD_ACTIVITY,
	{
		day: DayString;
	}
>;

type UpdateActivityAction = Action<
	CreateActionTypes.UPDATE_ACTIVITY,
	{
		activity: NewActivity;
	}
>;

type DeleteActivityAction = Action<
	CreateActionTypes.DELETE_ACTIVITY,
	{
		id: string;
		day: DayString;
	}
>;

type UpdateBreakAction = Action<
	CreateActionTypes.UPDATE_BREAK,
	{
		day: DayString;
		breakHours: number;
	}
>;

type UpdateNoteAction = Action<
	CreateActionTypes.UPDATE_NOTE,
	{
		day: DayString;
		note: string;
	}
>;

type ClearTimesheetAction = Action<CreateActionTypes.CLEAR_TIMESHEET>;

type ValidateTimesheetAction = Action<CreateActionTypes.VALIDATE_TIMESHEET>;

type CloseConfirmDialogAction = Action<CreateActionTypes.CLOSE_CONFIRM_DIALOG>;

export type CreateActions =
	| SelectWorkerAction
	| SelectClientAction
	| SelectSiteAction
	| SelectTimesheetStatusAction
	| SelectWeekEndingAction
	| UpdateLoadingAction
	| UpdateSiteLogsAction
	| AddActivityAction
	| UpdateActivityAction
	| DeleteActivityAction
	| UpdateBreakAction
	| UpdateNoteAction
	| ClearTimesheetAction
	| ValidateTimesheetAction
	| CloseConfirmDialogAction;

export const selectWorker = (
	worker: CreateState['selectedWorker'],
): SelectWorkerAction => ({
	type: CreateActionTypes.SELECT_WORKER,
	payload: { worker },
});

export const selectClient = (
	client: CreateState['selectedClient'],
): SelectClientAction => ({
	type: CreateActionTypes.SELECT_CLIENT,
	payload: { client },
});

export const selectSite = (
	site: CreateState['selectedSite'],
	defaultSite = false,
): SelectSiteAction => ({
	type: CreateActionTypes.SELECT_SITE,
	payload: { site, defaultSite },
});

export const selectTimesheetStatus = (
	timesheetStatus: CreateState['selectedTimesheetStatus'],
): SelectTimesheetStatusAction => ({
	type: CreateActionTypes.SELECT_TIMESHEET_STATUS,
	payload: { timesheetStatus },
});

export const selectWeekEnding = (
	weekEnding: CreateState['selectedWeekEnding'],
): SelectWeekEndingAction => ({
	type: CreateActionTypes.SELECT_WEEK_ENDING,
	payload: { weekEnding },
});

export const updateLoading = (
	loading: CreateState['initialLoad'],
): UpdateLoadingAction => ({
	type: CreateActionTypes.UPDATE_LOADING,
	payload: { loading },
});

export const updateSiteLogs = (
	siteLogs: CreateState['siteLogs'],
): UpdateSiteLogsAction => ({
	type: CreateActionTypes.UPDATE_SITE_LOGS,
	payload: { siteLogs },
});

export const addActivity = (day: DayString): AddActivityAction => ({
	type: CreateActionTypes.ADD_ACTIVITY,
	payload: { day },
});

export const updateActivity = (
	activity: NewActivity,
): UpdateActivityAction => ({
	type: CreateActionTypes.UPDATE_ACTIVITY,
	payload: { activity },
});

export const deleteActivity = (
	day: DayString,
	id: string,
): DeleteActivityAction => ({
	type: CreateActionTypes.DELETE_ACTIVITY,
	payload: { day, id },
});

export const updateBreak = (
	day: DayString,
	breakHours: number,
): UpdateBreakAction => ({
	type: CreateActionTypes.UPDATE_BREAK,
	payload: { day, breakHours },
});

export const updateNote = (day: DayString, note: string): UpdateNoteAction => ({
	type: CreateActionTypes.UPDATE_NOTE,
	payload: { day, note },
});

export const clearTimesheet = (): ClearTimesheetAction => ({
	type: CreateActionTypes.CLEAR_TIMESHEET,
});

export const validateTimesheet = (): ValidateTimesheetAction => ({
	type: CreateActionTypes.VALIDATE_TIMESHEET,
});

export const closeConfirmDialog = (): CloseConfirmDialogAction => ({
	type: CreateActionTypes.CLOSE_CONFIRM_DIALOG,
});
