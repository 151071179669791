import {
	Activity,
	Company,
	DayString,
	Site,
	SiteLog,
	UserDetails,
} from '../../../../constants/Common';
import { Note } from '../../../../constants/Note';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { NewTimesheetStatus } from '../../../../constants/Timesheet/TimesheetUtilities';
import { HeaderFields } from './HeaderFields';

export type NewActivity = Pick<Activity, 'id' | 'day' | 'hours'> & {
	activity: Activity['activity'] | null; // null temp new
	hasError: boolean;
};

export const isNonNullNewActivity = (
	activity: NewActivity,
): activity is NewActivity & {
	activity: NonNullable<NewActivity['activity']>;
} => activity.activity !== null;

type CreateStateBase = {
	creator: Timesheet['lastEditedBy'];
	duplicateTimesheet: null;
	initialLoad: boolean;
	confirmModalOpen: boolean;
	siteLogs: SiteLog[];
	headerErrors: Record<HeaderFields, boolean>;
	selectedWorker: Pick<
		UserDetails,
		| 'userID'
		| 'company'
		| 'companyID'
		| 'contractedTo'
		| 'displayName'
		| 'recentClients'
		| 'workerType'
	> | null;
	selectedClient: Pick<Company, 'id' | 'name'> | null;
	selectedSite: Site | null;
	selectedTimesheetStatus: NewTimesheetStatus;
	selectedWeekEnding: Date;
	activities: Record<DayString, Record<Activity['id'], NewActivity>>;
	breaks: Record<DayString, number>;
	notes: Record<DayString, Pick<Note, 'note' | 'user'>>;
	authorizedActions: Record<
		| 'canChangeClient'
		| 'canChangeSite'
		| 'canChangeStatus'
		| 'isRecruitmentSite',
		boolean
	>;
};

export type CreateState = CreateStateBase & {
	initialState: CreateStateBase;
};
