import { endOfWeek, subWeeks } from 'date-fns';
import { cloneDeep } from 'lodash';
import {
	accountTypes,
	CompanyTypes,
	dayStrings,
	Site,
	UserDetails,
} from '../../../../constants/Common';
import { Note } from '../../../../constants/Note';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import { validTimesheetDateRange } from '../../../../constants/Timesheet/TimesheetUtilities';
import { getUuid } from '../../../helpers/uuidHelpers';
import { CreateActions, CreateActionTypes } from './actions';
import { HeaderFields } from './HeaderFields';
import { CreateState, NewActivity } from './model';

export const createInitialCreateState = (initArg: {
	userDetails: Pick<
		UserDetails,
		| 'userID'
		| 'displayName'
		| 'accountType'
		| 'contractedTo'
		| 'siteID'
		| 'disableTimesheetApproval'
	>;
	sites: Record<string, Site>;
	weekEnding: Date;
	loading: boolean;
}): CreateState => {
	const initialSite = initArg.sites[initArg.userDetails.siteID] ?? null;

	const canChangeClient =
		initArg.userDetails.accountType === accountTypes.handler;
	const canChangeSite =
		initArg.userDetails.accountType !== accountTypes.management;
	const canChangeStatus =
		initArg.userDetails.accountType === accountTypes.seniorManagement ||
		(initArg.userDetails.accountType === accountTypes.management &&
			!initArg.userDetails.disableTimesheetApproval);
	const isRecruitmentSite =
		initialSite && initialSite.companyType === CompanyTypes.recruitment;

	const initialClient =
		isRecruitmentSite && initialSite
			? {
					id: initialSite.companyID,
					name: initialSite.company,
			  }
			: !canChangeClient
			? initArg.userDetails.contractedTo
			: null;
	const initialTimesheetStatus = !canChangeStatus
		? TimesheetStatus.Submitted
		: TimesheetStatus.Approved;

	const dateLimits = validTimesheetDateRange(new Date());
	const initialWeekEnding =
		initArg.weekEnding >= dateLimits.minDate &&
		initArg.weekEnding <= dateLimits.maxDate
			? initArg.weekEnding
			: subWeeks(endOfWeek(new Date()), 1);

	const initialNote: Pick<Note, 'note' | 'user'> = {
		note: '',
		user: {
			id: initArg.userDetails.userID,
			name: initArg.userDetails.displayName,
		},
	};

	const initialState: CreateState['initialState'] = {
		creator: {
			id: initArg.userDetails.userID,
			name: initArg.userDetails.displayName,
		},
		duplicateTimesheet: null,
		initialLoad: initArg.loading,
		confirmModalOpen: false,
		siteLogs: [],
		headerErrors: {
			[HeaderFields.Worker]: false,
			[HeaderFields.Client]: false,
			[HeaderFields.Site]: false,
			[HeaderFields.Status]: false,
			[HeaderFields.WeekEnding]: false,
		},
		selectedWorker: null,
		selectedClient: initialClient,
		selectedSite: initialSite,
		selectedTimesheetStatus: initialTimesheetStatus,
		selectedWeekEnding: initialWeekEnding,
		activities: {
			Monday: {},
			Tuesday: {},
			Wednesday: {},
			Thursday: {},
			Friday: {},
			Saturday: {},
			Sunday: {},
		},
		breaks: {
			Monday: 0,
			Tuesday: 0,
			Wednesday: 0,
			Thursday: 0,
			Friday: 0,
			Saturday: 0,
			Sunday: 0,
		},
		notes: {
			Monday: { ...initialNote },
			Tuesday: { ...initialNote },
			Wednesday: { ...initialNote },
			Thursday: { ...initialNote },
			Friday: { ...initialNote },
			Saturday: { ...initialNote },
			Sunday: { ...initialNote },
		},
		authorizedActions: {
			canChangeClient,
			canChangeSite,
			canChangeStatus,
			isRecruitmentSite,
		},
	};

	return {
		...initialState,
		initialState: { ...initialState },
	};
};

export const createTimesheetReducer = (
	state: CreateState,
	action: CreateActions,
): CreateState => {
	switch (action.type) {
		case CreateActionTypes.SELECT_WORKER:
			return {
				...state,
				selectedWorker: action.payload.worker,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Worker]: false,
				},
			};
		case CreateActionTypes.SELECT_CLIENT:
			return {
				...state,
				selectedClient: action.payload.client,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Client]: false,
				},
			};
		case CreateActionTypes.SELECT_SITE: {
			const isRecruitmentSite =
				action.payload.site?.companyType === CompanyTypes.recruitment;

			// If the site is a recruitment site, the client should be the recruitment company
			const client =
				isRecruitmentSite && action.payload.site
					? {
							id: action.payload.site.companyID,
							name: action.payload.site.company,
					  }
					: !isRecruitmentSite &&
					  state.authorizedActions.isRecruitmentSite
					? null
					: state.selectedClient;
			// As Timesheets.tsx isn't loading correctly one default set action is happening
			if (action.payload.defaultSite) {
				state.initialState.selectedSite = action.payload.site;
			}
			return {
				...state,
				selectedSite: action.payload.site,
				selectedClient: client,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Site]: false,
				},
				activities: {
					Monday: {},
					Tuesday: {},
					Wednesday: {},
					Thursday: {},
					Friday: {},
					Saturday: {},
					Sunday: {},
				},
				authorizedActions: {
					...state.authorizedActions,
					isRecruitmentSite: isRecruitmentSite,
				},
			};
		}
		case CreateActionTypes.SELECT_TIMESHEET_STATUS:
			return {
				...state,
				selectedTimesheetStatus: action.payload.timesheetStatus,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Status]: false,
				},
			};
		case CreateActionTypes.SELECT_WEEK_ENDING:
			return {
				...state,
				selectedWeekEnding: action.payload.weekEnding,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.WeekEnding]: false,
				},
			};
		case CreateActionTypes.UPDATE_LOADING:
			return {
				...state,
				initialLoad: action.payload.loading,
			};
		case CreateActionTypes.UPDATE_SITE_LOGS:
			return {
				...state,
				siteLogs: action.payload.siteLogs,
			};
		case CreateActionTypes.ADD_ACTIVITY: {
			const tempActivityID = `new-${getUuid()}`;
			const newActivity: NewActivity = {
				id: tempActivityID,
				day: action.payload.day,
				activity: null,
				hours: 0,
				hasError: false,
			};
			return {
				...state,
				activities: {
					...state.activities,
					[action.payload.day]: {
						...state.activities[action.payload.day],
						[tempActivityID]: newActivity,
					},
				},
			};
		}
		case CreateActionTypes.UPDATE_ACTIVITY: {
			const updatedActivities = cloneDeep(state.activities);
			updatedActivities[action.payload.activity.day][
				action.payload.activity.id
			] = action.payload.activity;

			return {
				...state,
				activities: updatedActivities,
			};
		}
		case CreateActionTypes.DELETE_ACTIVITY: {
			const updatedActivities = cloneDeep(state.activities);
			delete updatedActivities[action.payload.day][action.payload.id];

			return {
				...state,
				activities: updatedActivities,
			};
		}
		case CreateActionTypes.UPDATE_BREAK: {
			// Breaks are limited to 4 hours
			if (
				action.payload.breakHours < 0 ||
				action.payload.breakHours > 4
			) {
				return state;
			} else {
				return {
					...state,
					breaks: {
						...state.breaks,
						[action.payload.day]: action.payload.breakHours,
					},
				};
			}
		}
		case CreateActionTypes.UPDATE_NOTE:
			return {
				...state,
				notes: {
					...state.notes,
					[action.payload.day]: {
						...state.notes[action.payload.day],
						note: action.payload.note,
					},
				},
			};
		case CreateActionTypes.CLEAR_TIMESHEET:
			return {
				...state.initialState,
				initialLoad: state.initialLoad,
				initialState: state.initialState,
			};
		case CreateActionTypes.VALIDATE_TIMESHEET: {
			let confirmModalOpen = true;
			const activities = cloneDeep(state.activities);
			dayStrings.forEach((day) => {
				Object.keys(activities[day]).forEach((activity) => {
					activities[day][activity].hasError =
						activities[day][activity].activity === null ||
						!(
							activities[day][activity].hours > 0 &&
							activities[day][activity].hours <= 24
						);
					if (activities[day][activity].hasError) {
						confirmModalOpen = false;
					}
				});
			});
			const newHeaderErrors = {
				[HeaderFields.Worker]: state.selectedWorker === null,
				[HeaderFields.Client]: state.selectedClient === null,
				[HeaderFields.Site]: state.selectedSite === null,
				[HeaderFields.Status]: !(
					state.selectedTimesheetStatus ===
						TimesheetStatus.Submitted ||
					state.selectedTimesheetStatus === TimesheetStatus.Approved
				),
				[HeaderFields.WeekEnding]: !state.selectedWeekEnding,
			};

			const headerHasErrors = Object.values(newHeaderErrors).some(
				(error) => error,
			);
			confirmModalOpen = confirmModalOpen && !headerHasErrors;

			return {
				...state,
				confirmModalOpen,
				activities,
				headerErrors: newHeaderErrors,
			};
		}
		case CreateActionTypes.CLOSE_CONFIRM_DIALOG:
			return {
				...state,
				confirmModalOpen: false,
			};

		default:
			return state;
	}
};
