import { Stack, Typography } from '@mui/material';
import { Activity } from '../../../../../constants/Common';
import { getTimeForHours } from '../../../../../constants/stringUtilities';
import { getActivityHourTotals } from '../../../../helpers/activityHelpers';

export type SummaryFooterProps = {
	activities: Pick<Activity, 'id' | 'activity' | 'hours'>[];
};

export const SummaryFooter = ({
	activities,
}: SummaryFooterProps): JSX.Element => {
	const { totals, allHours } = getActivityHourTotals(activities);
	return (
		<Stack alignItems="flex-end" sx={{ opacity: 0.6 }}>
			{Object.entries(totals).map(([id, total]) => (
				<Stack
					data-testid={id}
					key={id}
					direction="row"
					color="neutral">
					<Typography>{total.name}</Typography>
					<Typography whiteSpace="nowrap" marginLeft={1}>
						{getTimeForHours(total.total)}
					</Typography>
				</Stack>
			))}
			<Stack data-testid="total" direction="row">
				<Typography fontWeight="bold">Total</Typography>
				<Typography
					fontWeight="bold"
					whiteSpace="nowrap"
					marginLeft={1}>
					{getTimeForHours(allHours)}
				</Typography>
			</Stack>
		</Stack>
	);
};
